<template>
    <div class="tienda">
        <div style="background-color: #151616; min-height: calc(100vh - 100px)">
            <v-container>

                <v-row class="mt-6" align="center" justify="center" justify-md="start">
                    <v-col class="py-0" cols="10" sm="6">
                        <v-text-field
                            label="Buscar"
                            dark
                            v-model="busqueda"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row align="center" justify="center" justify-md="start">

                    <v-col cols="auto">
                        <v-btn to="/tienda" :disabled="(!disableTequilas && !disableMezcales)" dark depressed :ripple="false">Todos</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn to="/tienda/tequilas" :disabled="disableTequilas" dark depressed :ripple="false">Tequilas</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn to="/tienda/mezcales" :disabled="disableMezcales" dark depressed :ripple="false">Mezcales</v-btn>
                    </v-col>

                    <v-col cols="auto">
                        <v-select
                            :items="listaClases"
                            label="Clase"
                            dark
                            v-model="clase"
                        ></v-select>
                    </v-col>

                    <template v-if="$route.name == 'Tienda Mezcales'">
                        <v-col cols="auto">
                             <v-select
                                :items="listaAgaves"
                                label="Tipo de Agave"
                                dark
                                v-model="agave"
                            ></v-select>
                        </v-col>
                    </template>
                    
                </v-row>

                <v-row class="margin-custom">
                    <v-col cols="12" sm="4" md="3" v-for="(producto, pr) in productosFiltrados" :key="'producto-'+pr" class="text-center mb-7">
                        <div class="d-block mx-auto" style="max-width: 250px">
                            <router-link :to="'/tienda/productos/'+producto.url">
                                <v-img :src="'https://content.elmurcielagotequileria.com/'+producto.cover.path" aspect-ratio="1" contain class="img-producto"></v-img>
                            </router-link>
                            <router-link :to="'/tienda/productos/'+producto.url" class="not-decorated">
                                <span class="d-block text-center white--text bold">{{producto.nombre}}</span>
                            </router-link>
                            <router-link :to="'/tienda/productos/'+producto.url" class="not-decorated">
                                <span class="d-block text-center white--text">{{producto.categoria}}  {{producto?.clase?.name}} </span>
                            </router-link>
                            <hr class="hr-custom">
                            <div class="white--text text-center precio-margin">
                                <template v-if= "obtenerPresentaciones(producto._id).length > 0">
                                    Desde
                                    ${{numberWithCommas(obtenerPresentaciones(producto._id)[0].precio)}}
                                </template>
                            </div>
                            <v-btn outlined class="btn-producto" color="#A06A4C" :to="'/tienda/productos/'+producto.url">
                                <span class="white--text">Ver producto</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>

</template>

<script>
export default {
    metaInfo: {
        title: 'Tienda de Tequilas y Mezcales en México | El Murciélago Tequilería',
        // titleTemplate: 'Marisol Malibu',
        htmlAttrs: {
        lang: 'es'
        },
        meta: [
        {name:'description', content: 'Tienda con los mejores Tequilas y Mezcales Artesanales en México.'}
        ]
    },
    data () {
        return {
            productos: [],
            agaves: [],
            clases: [],
            presentaciones: [],
            loaded: false,
            agave: 'Todos',
            clase: 'Todos',
            busqueda: ''
        }
    },
    computed: {
        pTequilas: function () {
            var p = this.productos;
            p = p.filter(prod => prod.categoria == 'Tequila')

            if(this.clase != 'Todos'){
                p = p.filter(prod => prod.clase.name == this.clase)
            }

            return p;
        },
        pMezcales: function () {
            var p = this.productos;

            p = p.filter(prod => prod.categoria == 'Mezcal')

            if(this.agave != 'Todos'){
                p = p.filter(prod => prod?.agave?.name == this.agave)
            }

            if(this.clase != 'Todos'){
                p = p.filter(prod => prod?.clase?.name == this.clase)
            }

            return p;
        },
        productosFiltrados: function () {

            var p = this.productos;

            if(this.busqueda != ''){
                p = p.filter(prod => prod.nombre.toUpperCase().includes(this.busqueda.toUpperCase()));
            }
            
            if(this.$route.name == 'Tienda Tequilas'){
                return this.pTequilas;
            }

            if(this.$route.name == 'Tienda Mezcales'){
                return this.pMezcales;
            }

            if(this.clase != 'Todos'){
                p = p.filter(prod => prod?.clase?.name == this.clase)
            }

            return p;
        },
        disableMezcales: function () {
            return (this.$route.name == 'Tienda Mezcales')
        },
        disableTequilas: function () {
            return (this.$route.name == 'Tienda Tequilas')
        },
        listaAgaves: function () {
            var lista = ['Todos'];
            for (let index = 0; index < this.agaves.length; index++) {
                const element = this.agaves[index];
                lista.push(element.name);
                
            }

            return lista;
        },
        listaClases: function () {
            var lista = ['Todos'];
            for (let index = 0; index < this.clases.length; index++) {
                const element = this.clases[index];
                lista.push(element.name);
                
            }

            return lista;
        }
    },
    methods: {
        obtenerPresentaciones: function (idProducto) {
            let p = this.presentaciones.filter(presentacion => presentacion.producto._id == idProducto);

            p.sort(function(a,b) {
                if(a.precio > b.precio){
                    return 1;
                }

                if(a.precio < b.precio){
                    return -1;
                }

                return 0
            })

            return p;
        },
        numberWithCommas: function (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    beforeMount: function(){
        fetch('https://content.elmurcielagotequileria.com/api/collections/get/Productos', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {
                    activo: true
                },
                fields: {},
                sort: {},
                populate: 2, // resolve linked collection items
            })
        })
        .then(res=>res.json())
        .then(res => this.productos = res.entries);

        fetch('https://content.elmurcielagotequileria.com/api/collections/get/Agave', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {
                },
                fields: {},
                sort: {
                    name: 1
                },
            })
        })
        .then(res=>res.json())
        .then(res => this.agaves = res.entries);

        fetch('https://content.elmurcielagotequileria.com/api/collections/get/Clases', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {
                },
                fields: {},
                sort: {
                    name: 1
                },
            })
        })
        .then(res=>res.json())
        .then(res => this.clases = res.entries);

        fetch('https://content.elmurcielagotequileria.com/api/collections/get/Presentaciones', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    filter: {},
                    fields: {},
                    populate: 1, // resolve linked collection items
                })
            })
            .then(res=>res.json())
            .then(res => this.presentaciones = res.entries)
            .then(this.loaded = true)

    },
    watch: {
        $route: function () {
            this.agave = 'Todos';
            this.clase = 'Todos';
        }
    }
}
</script>